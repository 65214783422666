import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { Link } from "gatsby"
import classnames from "classnames"

import { FaTelegramPlane } from "react-icons/fa"

//import logoText from "../assets/images/mysite.svg"
//import logo from "../assets/images/logo.svg"
import Classes from "../styles/Classes"
import Mm from "../components/wallet"
import Myapp from "../components/_app"

import "../styles/hamburger.css"
import MetamaskDropdown from "../components/MetamaskDropdown"
import loadable from '@loadable/component'
import logo from "../assets/images/bsalogo.png"

const MMDP = loadable(() => import("../components/MetamaskDropdown"))
const MA = loadable(() => import("../components/_app"))


export const Logo = () => (
    <div className="flex flex-row items-center space-x-2">
        {/*<img src={logo} className="h-20 w-20" />*/}
        <Link to="https://www.twitter.com/Vrkoys" className={linkClassName}>
        {/*<img src={logoText} className="h-3" />*/}
            <span className="text-white"> 
                ARKOYS 
            </span>
        </Link>
    </div>
)

const Hamburger = ({ active = false, onClick = () => {} }) => (
    <div className="relative z-50 w-12 h-10 overflow-hidden transform scale-75 text-primary-200 md:hidden">
        <button
            className={classnames(
                `hamburger hamburger--emphatic`,
                active && "is-active",
                "text-primary-200"
            )}
            type="button"
            onClick={onClick}
        >
            <span className="hamburger-box">
                <span className="hamburger-inner"> </span>
            </span>
        </button>
    </div>
)

const linkClassName =
    "transition-all hover:font-normal hover:text-white hover:text-opacity-100 duration-300"

const BackdropNav = styled.div`
    @media (max-width: 767px) {
        backdrop-filter: blur(1rem);
    }
`

const Links = () => (
    <>
        {/*<Link to="https://mysite.io/#invest" className={linkClassName}>
            Invest
        </Link>
        <Link to="https://mysite.io/#features" className={linkClassName}>
            Lyscapital
        </Link>
        <Link to="https://mysite.io/#projects" className={linkClassName}>
            Projects
        </Link>
        <Link to="https://mysite.io/#faq" className={linkClassName}>
            FAQ
        </Link>
        <Link to="https://mysite.io/#partners" className={linkClassName}>
            Partners
        </Link>*/}
    </>
)


const Header = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    return (
        <BackdropNav className="fixed left-0 right-0 z-50 flex flex-row justify-between px-4 py-4 mx-auto md:px-0 md:relative md:w-full md:max-w-7xl md:py-8">
            {<Logo />}
            <div className="flex flex-row items-center space-x-4">
                <div className="flex-row items-center hidden space-x-4 text-sm font-thin text-white text-opacity-50 md:flex">
                    <Links />
                </div>
                {/*<a
                    href="https://t.me/mysite"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={Classes.baseButton}
                >
                    <FaTelegramPlane size="0.9rem" />
                </a>
                <Hamburger
                    active={sidebarOpen}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                />*/}
                {/*<Link to="/ComingSoon" className={Classes.textButtonInverted}>
                Dashboard
                </Link>*/}
            
            {/*<MA
            Component={MMDP} 
            pageProps={MMDP}
            />*/}
            </div>
            {/*
            <div
                className={classnames(
                    "fixed top-0 left-0 w-screen h-screen bg-black transition-all duration-300",
                    sidebarOpen ? "opacity-50 visible" : "opacity-0 invisible"
                )}
            />
            
            <div
                className={classnames(
                    "fixed md:hidden w-60 h-screen z-20 p-8 pt-20 top-0 right-0 transition-all duration-300 bg-gray-700 transform",
                    !sidebarOpen && "translate-x-full",
                    "flex flex-col space-y-8 text-primary-200"
                )}
            >
                <Links />
            </div>*/}
        </BackdropNav>
    )
}

export default Header
