/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Footer from "./Footer"
import Header from "./Header"

import background from "../assets/images/background.svg"

const PageLayout = ({ children }) => {
    return (
        <div className="relative px-4">
            {/*
            <div
                className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none opacity-5"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundPositionX: "center",
                    backgroundPositionY: "top",
                    backgroundSize: "cover",
                    backgroundRepeat: "none",
                }}
            />
            */}
            <Header />
            <main className="pt-20 md:pt-0">{children}</main>
            <Footer />
        </div>
    )
}

export default PageLayout
