import { Link } from "gatsby"
import React from "react"

import { FaTwitter, FaTelegramPlane, FaMedium } from "react-icons/fa"
import { IoCaretUpCircleOutline } from "react-icons/io5"
import { Logo } from "./Header"

const Footer = () => (
    <footer className="flex flex-col w-full max-w-3xl md:max-w-7xl mx-auto">
        <div className="flex flex-row items-center justify-around w-full text-xl md:justify-around text-white">
            <a
                href="https://twitter.com/Vrkoys"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FaTwitter />
            </a>
            {/*<a
                href="https://t.me/mysite"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FaTelegramPlane />
            </a>
            <a
                href="https://mysite.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
            >
                <FaMedium />
            </a>*/}
        </div>
        {/*
        <div className="relative my-8">
            <div
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-0.5 bg-white opacity-25"
                style={{ width: "calc(100% + 12rem)" }}
            />
        </div>*/}
        {/*<div className="flex flex-col w-full mb-8">
            <div className="relative flex flex-row items-center justify-between w-full">
                <Logo />
                <span className="absolute hidden text-xs text-white transform -translate-x-1/2 -translate-y-1/2 md:block left-1/2 top-1/2">
                    Copyright © 2022 Arkoys: All Rights Reserved
                </span>
                <Link to="#" className="text-2xl text-white">
                    <IoCaretUpCircleOutline />
                </Link>
            </div>
            <span className="w-full mt-8 text-xs text-center text-gray-400 md:hidden">
                Copyright © 2022 Arkoys: All Rights Reserved
            </span>
        </div>*/}   
    </footer>
)

export default Footer
